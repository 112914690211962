import React from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash.kebabcase';

import accreditationIconUrl from '~assets/illustrations/accreditation.svg';
import claimIconUrl from '~assets/illustrations/claim.svg';
import contactIconUrl from '~assets/illustrations/contact.svg';
import coronialInquestIconUrl from '~assets/illustrations/coronial-inquest.svg';
import disabilityInsuranceIconUrl from '~assets/illustrations/disability-insurance.svg';
import dustDiseaseIconUrl from '~assets/illustrations/dust-disease.svg';
import dustExposureIconUrl from '~assets/illustrations/dust-exposure.svg';
import employmentLawIconUrl from '~assets/illustrations/employment-law.svg';
import expertAdviceIconUrl from '~assets/illustrations/expert-advice.svg';
import inAPublicPlaceIconUrl from '~assets/illustrations/in-a-public-place.svg';
import inTheWorkplaceIconUrl from '~assets/illustrations/in-the-workplace.svg';
import medicalNegligenceIconUrl from '~assets/illustrations/medical-negligence.svg';
import medicalProfessionalIconUrl from '~assets/illustrations/medical-professional.svg';
import medicalTreatmentIconUrl from '~assets/illustrations/medical-treatment.svg';
import noWinNoFeeIconUrl from '~assets/illustrations/no-win-no-fee.svg';
import notifyEmployerIconUrl from '~assets/illustrations/notify-employer.svg';
import onTheRoadIconUrl from '~assets/illustrations/on-the-road.svg';
import publicLiabilityIconUrl from '~assets/illustrations/public-liability.svg';
import sexualAbuseIconUrl from '~assets/illustrations/sexual-abuse.svg';
import trafficAccidentIconUrl from '~assets/illustrations/traffic-accident.svg';
import weCareIconUrl from '~assets/illustrations/we-care.svg';
import workplaceInjuryIconUrl from '~assets/illustrations/workplace-injury.svg';
import incomeProtectionIconUrl from '~assets/illustrations/income-protection.svg';
import superannuationIconUrl from '~assets/illustrations/superannuation.svg';
import investigationIconUrl from '~assets/illustrations/investigation.svg';
import submitFormIconUrl from '~assets/illustrations/submit-form.svg';
import medicalMaskIconUrl from '~assets/illustrations/medical-mask.svg';
import infectionIconUrl from '~assets/illustrations/infection.svg';
import businessmanIconUrl from '~assets/illustrations/businessman.svg';
import workerIconUrl from '~assets/illustrations/worker.svg';
import redlichsLogoIconUrl from '~assets/illustrations/redlichs-logo.svg';
import redlichsLogoInverseIconUrl from '~assets/illustrations/redlichs-logo-inverse.svg';

export const allIllustrations = {
    'accreditation': accreditationIconUrl,
    'claim': claimIconUrl,
    'contact': contactIconUrl,
    'coronial-inquest': coronialInquestIconUrl,
    'disability-insurance': disabilityInsuranceIconUrl,
    'dust-disease': dustDiseaseIconUrl,
    'dust-exposure': dustExposureIconUrl,
    'employment-law': employmentLawIconUrl,
    'expert-advice': expertAdviceIconUrl,
    'in-a-public-place': inAPublicPlaceIconUrl,
    'in-the-workplace': inTheWorkplaceIconUrl,
    'medical-negligence': medicalNegligenceIconUrl,
    'medical-professional': medicalProfessionalIconUrl,
    'medical-treatment': medicalTreatmentIconUrl,
    'no-win-no-fee': noWinNoFeeIconUrl,
    'notify-employer': notifyEmployerIconUrl,
    'on-the-road': onTheRoadIconUrl,
    'public-liability': publicLiabilityIconUrl,
    'sexual-abuse': sexualAbuseIconUrl,
    'traffic-accident': trafficAccidentIconUrl,
    'we-care': weCareIconUrl,
    'workplace-injury': workplaceInjuryIconUrl,
    'income-protection': incomeProtectionIconUrl,
    'superannuation': superannuationIconUrl,
    'investigation': investigationIconUrl,
    'submit-form': submitFormIconUrl,
    'medical-mask': medicalMaskIconUrl,
    'infection': infectionIconUrl,
    'businessman': businessmanIconUrl,
    'worker': workerIconUrl,
    'redlichs-logo': redlichsLogoIconUrl,
    'redlichs-logo-inverse': redlichsLogoInverseIconUrl,
};


const IconIllustration = ({icon, altText}) => {
    const iconUrl = allIllustrations[kebabCase(icon)]  ;

    return <img src={iconUrl} alt={`${altText ? altText : `${icon} Icon`}`} />;
};

export default IconIllustration;

IconIllustration.propTypes = {
    icon: PropTypes.string.isRequired,
    altText: PropTypes.string
};
